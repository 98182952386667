<template>
    <div id="teste">    
      </div>
  </template>
  
  <script>
  export default {
  
    created() {
      console.log(this.$arquivoProtegido(this.$route.query.url))
      window.location.href = this.$arquivoProtegido(this.$route.query.url)
  
    },
  }
  </script>
  
  
<template>
  <div>

    <v-container fluid id="element">
      <v-card class="mt-2 py-4 px-2" elevation="20" color="primary">
        <v-row no-gutters>
          <v-col  class="px-2">
            <v-toolbar-title class="ml-2 mt-2 " style="color: #fff">
              Avisos Aplicativo
            </v-toolbar-title></v-col
          >
        </v-row>
      </v-card>
    </v-container>

    <v-container fluid id="element">
      <v-row align="end" justify="end">
        <v-col cols="12" align="end" justify="end">
          <v-btn style="margin-right: 10px" color="primary" @click="dialogCriaAviso()">
            <v-icon left>mdi-plus-thick</v-icon> Criar Aviso
          </v-btn> 
          <v-btn style="margin-right: 10px" v-if="selectedLines.length > 0"  color="error" @click="excluir()">
            <v-icon left>mdi-microsoft-excel</v-icon> Excluir avisos selecionados
          </v-btn>
          <v-btn style="margin-right: 10px"  color="primary" @click="dialogImportar = true">
            <v-icon left>file_upload</v-icon> Importar
          </v-btn>
          <v-btn style="margin-right: 10px"  color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-2 py-4 px-2" elevation="10">
        <v-row no-gutters height="50">
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Filtro Texto"
              v-model="filtro.texto"
              outlined
              dense
              type="text"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Período Inicial"                
              v-model="filtro.mesInicial"      
              :rules="regra.mesInicial"  
              :min="'2022-07-01'"
              required
              outlined
              dense 
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Período Final"                
              v-model="filtro.mesFinal"      
              :rules="regra.mesFinal"  
              :min="filtro.mesInicial"
              required
              outlined
              dense 
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-autocomplete
                outlined
                dense
                clearable
                multiple
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.filial"
                :items="lojas"
                item-text="label"
                item-value="id_java"
                label="Filial"
              >
              </v-autocomplete>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-autocomplete
                outlined
                dense
                clearable
                multiple
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.cluster"
                :items="cluster"
                item-text="nome"
                item-value="id_cluster"
                label="Cluster"
              >
              </v-autocomplete>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-autocomplete
                outlined
                dense
                clearable
                multiple
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.estado"
                :items="estados"
                item-text="estado"
                item-value="estado"
                label="Estados"
              >
              </v-autocomplete>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-autocomplete
                outlined
                dense
                clearable
                multiple
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.bandeira"
                :items="bandeiras"
                item-text="bandeira"
                item-value="bandeira"
                label="Bandeira"
              >
              </v-autocomplete>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-autocomplete
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.status"
                :items="status"
                item-text="label"
                item-value="id_status"
                label="Status"
              >
              </v-autocomplete>
          </v-col>

          
        </v-row>

        <v-row align="end" justify="end">
          <v-col cols="12" align="end" justify="end">
            <v-btn style="margin-right: 10px"  color="primary" @click="filtrar()">
              <v-icon left>mdi-filter</v-icon> Filtrar
            </v-btn>
            <v-btn  color="primary" @click="limpaFiltro()">
              <v-icon left>mdi-filter-off</v-icon> Limpar
            </v-btn>
          </v-col>
        </v-row>

      </v-card>
    </v-container>

    <v-container fluid>

      <v-dialog v-model="dialog" scrollable max-width="1000">
        <v-card class="mt-2 py-4 px-2" elevation="1">
          <form @submit.prevent="Salvar">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="2" sm="4" md="4" lg="4" xl="4" class="px-2">
                  <v-autocomplete
                    v-if="!editandoAviso"
                    outlined
                    dense
                    multiple
                    v-model="filtroCriaAviso.filiais"
                    :items="lojas"
                    item-text="label"
                    item-value="id_java"
                    :label="(filtroCriaAviso.filiais && filtroCriaAviso.filiais.length !== 0) ? 'Filiais' : 'Todas filiais'"
                    @blur="filterCriaAviso()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="2" sm="3" md="3" lg="3" xl="3" class="px-2">
                  <v-autocomplete
                    v-if="!editandoAviso"
                    outlined
                    dense
                    multiple
                    v-model="filtroCriaAviso.clusters"
                    :items="cluster"
                    item-text="nome"
                    item-value="id_cluster"
                    :label="(filtroCriaAviso.clusters && filtroCriaAviso.clusters.length !== 0) ? 'Clusters' : 'Todos clusters'"
                    @blur="filterCriaAviso()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
                  <v-autocomplete
                      outlined
                      dense
                      multiple
                      v-model="filtroCriaAviso.estado"
                      :items="estados"
                      item-text="estado"
                      item-value="estado"
                      :label="(filtroCriaAviso.estado && filtroCriaAviso.estado.length !== 0) ? 'Estados' : 'Todos estados'"
                      @blur="filterCriaAviso()"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
                  <v-autocomplete
                      outlined
                      dense
                      multiple
                      v-model="filtroCriaAviso.bandeira"
                      :items="bandeiras"
                      item-text="bandeira"
                      item-value="bandeira"
                      :label="(filtroCriaAviso.bandeira && filtroCriaAviso.bandeira.length !== 0) ? 'Bandeira' : 'Todas bandeiras'"
                      @blur="filterCriaAviso()"
                    >
                    </v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12" md="8" class="px-2">
                  <v-textarea
                    required
                    :counter="1000"
                    filled
                    shaped
                    append-icon="mdi-comment"
                    background-color="amber lighten-4"
                    color="orange orange-darken-4"
                    label="Escreva o aviso..."
                    v-model="aviso.mensagem"
                  ></v-textarea>
                </v-col>
                <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="px-3">
                  <v-text-field
                    label="Data de envio"                
                    v-model="aviso.data_envio"  
                    :min="'2022-07-01'"
                    required
                    outlined
                    dense 
                    type="date"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" md="8" class="px-2">
                  <span v-if="!editandoAviso">Esse aviso será enviado para {{quantidadeLojasCriacao}} loja(s)</span>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions elevation="20">
              <v-row align="end" justify="end">
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                  lg="3"
                  xl="3"
                  align="end"
                  justify="end"
                >
                  <v-btn color="error"  @click="mensagem={},dialog = false">Cancelar</v-btn>
                  <v-btn class="ml-2" v-if="quantidadeLojasCriacao > 0" type="submit" color="primary">
                    <v-icon left>save</v-icon> Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-container>

    <v-container fluid>

      <v-dialog v-model="dialogImportar" max-width="50%">
          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              Importar Avisos!
            </v-card-title>
            <v-card-text class="mt-4"> </v-card-text>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-alert border="left" colored-border prominent dense text type="info">
                    <p>O arquivo CSV deve conter as seguintes colunas: id_java, mensagem e data_envio</p>
                    <p>OBS: data_envio no formato ano-mes-dia. Ex: 2022-08-30</p>
                  </v-alert>                 
                </v-row>
                <v-row>
                   <v-col cols="12" sm="8" md="8">
                      <v-btn @click="DownloadBase" color="primary">
                        <v-icon left>file_download</v-icon>
                        <span>Download base</span>
                      </v-btn>
                    <v-file-input 
                      accept=".csv" 
                      label="Arquivo csv" 
                      show-size 
                      @change="fileSelected" 
                      prepend-icon="insert_drive_file" 
                      v-model="fileCsv"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialogImportar = false">
                <v-icon>mdi-close</v-icon> Cancelar</v-btn
              >
              <v-btn class="cyan" @click="criarAvisoImportado()">
                <v-icon>mdi-chevron-right</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>

    <v-container fluid>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <template>
            <v-data-table
              v-model="selectedLines"
              :headers="headers"
              :items="dados"
              item-key="id_aviso"
              show-select
              class="class-on-data-table"
            >
              <template v-slot:[`item.data_criacao`]="{ item }">
                {{ item.data_criacao | moment }}
              </template>
              <template v-slot:[`item.mensagem`]="{ item }">
                <span>{{ resumirMensagem(item.mensagem) }}</span>
              </template>
              <template v-slot:[`item.data_visualizacao`]="{ item }">              
                <span v-if="item.data_visualizacao">{{ item.data_visualizacao | moment }}</span>
              </template>

              <!-- ========
            Menu 
            ======= -->
              <template v-slot:[`item.acao`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
                      mdi-menu
                    </v-icon>
                  </template>

                  <v-card>
                    <v-list dense nav>
                      <v-list-item link @click="editando(item), dialog = true">
                        <v-list-item-icon>
                          <v-icon color="primary">mdi-file-document-edit</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Editar</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item link @click="Delete(item.id_aviso)">
                        <v-list-item-icon>
                          <v-icon color="withe">mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>

              <!-- ========
            Menu 
            ======= -->
            </v-data-table>
          </template>
        </v-row>
      </v-card>
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar :color="color" v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="withe" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";

export default {

  data: function () {

    return {
      lojasBackup: [],
      clusterBackup: [],
      bandeiraBackup: [],
      estadoBackup: [],
      snackbar: false,
      snackbarText: "",
      color: null,
      dados: [],
      anosMeses: [],
      overlay: true,
      headers: [
        { text: "", value: "", align: "left", width: "3%" },
        { text: "Filial", value: "desc_filial", align: "left", width: "20%" },
        { text: "Mensagem", align: "left", value: "mensagem", width: "40%" },
        { text: "Cluster", align: "center", value: "cluster", width: "10%" },
        { text: "Estado", align: "center", value: "estado", width: "5%" },
        { text: "Bandeira", align: "center", value: "bandeira", width: "10%" },
        { text: "Status", align: "center", value: "desc_status", width: "10%"},
        { text: "Data de envio", align: "center", value: "data_criacao", width: "10%" },
        { text: "Data de visualização", align: "center", value: "data_visualizacao", width: "10%" },
      ],
      aviso: {},
      editandoAviso: false,
      dialog: false,
      indicador: "arrow_forward",
      filtro: {
        mesInicial: this.getFirstDayAtMonth(),
        mesFinal: this.getLastDayAtMonth()
      },
      filtroCriaAviso: {},
      dadosOriginais: [],
      filiais: [],
      lojas: [],
      cluster: [],
      estados: [],
      bandeiras: [],
      status: [
        {
          label: 'Não lido',
          id_status: 0
        },
        {
          label: 'Lido',
          id_status: 1
        }
      ],
      quantidadeLojasCriacao: 0,
      regra: {
        mesInicial: [
            v => !!v || 'O campo período inicial é obrigatório',
        ],            
        mesFinal: [
            v => !!v || 'O campo período final é obrigatório',
            v => (!!v && v >= this.filtro.mesInicial) || 'O período final deve ser igual ou posterior ao período inicial',
        ],  
      },
      selectedLines: [],
      dialogImportar: false,
      csv_file: '',
      avisosImport: [],
      fileCsv: undefined
    };
  },
  async mounted() {
    this.listaCluster();
    this.listaEstados();
    this.listaBandeiras();
    await this.listaAnosMeses();
    this.lojas = await this.buscaLojas();

    this.lojas = this.lojas.map((val) => ({
      ...val,
      label: val.id_java + " - " + val.descricao,
    }));
    this.lojas.unshift({label: 'TODOS', id_java: -1});
    this.lojasBackup = this.lojas;

    await this.listaAvisos();
  },

  methods: {
    getFirstDayAtMonth() {
      const month = (new Date().getMonth() + 1) > 9 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`
      return `${new Date().getFullYear()}-${month}-01`;
    },
    getLastDayAtMonth() {
      const month = (new Date().getMonth() + 1) > 9 ? new Date().getMonth() + 1 : `0${new Date().getMonth() + 1}`
      const lastDayMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
      console.log(`${new Date().getFullYear()}-${month}-${lastDayMonth.getDate()}`)
      return `${new Date().getFullYear()}-${month}-${lastDayMonth.getDate()}`;
    },
    async buscaLojas() {
      return (
      await axios.get(`${this.$SERVER_URL}adm/loja`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
      ).data
    },
    DownloadBase(){
      const data = [{
        'id_java':'',
        'mensagem':'',
        'data_envio': ''
      }]
      const ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Avisos para Importação");
        XLSX.writeFile(wb, `base_modelo_importacao_avisos.xlsx`);
    },
    async criarAvisoImportado() {
      this.aviso.userid = Number(localStorage.getItem("userid"));
      await axios
        .post(`${this.$SERVER_URL}aviso/cadastrarImportados`, { avisos: this.avisosImport, userId: this.aviso.userid }, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.mensagem = {}; //limpar os dados setados
          this.filtroCriaAviso = {};
          this.listaAvisos();
          this.csv_file = '';
          this.avisosImport = [];
          this.dialogImportar = false;
          this.fileCsv = undefined;
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    readerData(rawFile, sheet) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const selectedSheet = workbook.SheetNames[sheet];
          const worksheet = workbook.Sheets[selectedSheet];
          // const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: selectedSheet };
          resolve({ results, meta });
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    async fileSelected($event){
			this.csv_file = $event;
      this.avisosImport = (await this.readerData(this.csv_file, 0)).results;
      console.log(this.avisosImport)
    },
    async excluir() {
      await axios
        .post(
          `${this.$SERVER_URL}aviso/deletar`,
          { avisos: this.selectedLines },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.selectedLines = [];
          this.listaAvisos();
         
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    resumirMensagem(mensagem) {
      const limite = 80;
      return mensagem.length > limite
        ? mensagem.substring(0, limite) + "..."
        : mensagem;
    },
    exportar() {
      var avisos = [];
      this.dados.forEach((val) => {

        avisos.push({
          'id_aviso': val.id_aviso,
          'id_java': val.id_java,
          'filial': val.filial,
          'mensagem': val.mensagem,
          'status': val.desc_status,
          'data_visualizacao': val.data_visualizacao,
          'data_de_envio': val.data_criacao,
       
        });
      });
 
      const ws = XLSX.utils.json_to_sheet(avisos);
      let wb = XLSX.utils.book_new();
  
      XLSX.utils.book_append_sheet(wb, ws, "Avisos");
      XLSX.writeFile(wb, `avisos${new Date().getTime()}.xlsx`);
    },
    dialogCriaAviso() {
      this.editandoAviso = false;
      this.dialog = true;
      this.aviso.mensagem = '';
      this.filtroCriaAviso = {};
      this.filterCriaAviso();
      this.listaCluster();
      this.listaEstados();
      this.listaBandeiras();

      this.lojas = this.lojasBackup;
      this.cluster = this.clusterBackup;
      this.estados = this.estadoBackup;
      this.bandeiras = this.bandeiraBackup;
    },
    async filterCriaAviso() {
      await axios
        .post(`${this.$SERVER_URL}aviso/listaLojasCriacao`, { filtro: this.filtroCriaAviso }, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.lojasCriacao = response.data.map(i => { return i.id });
          this.quantidadeLojasCriacao = this.lojasCriacao.length;
        });
    },
    filtrar() {
      this.listaAvisos();
    },
    limpaFiltro() {
      this.filtro = {
        mesInicial: '2022-07',
        mesFinal: '2022-07'
      }
    },
    async editando(item) {
      this.filtroCriaAviso = {};
      this.filterCriaAviso();
      this.listaCluster();
      this.listaEstados();
      this.listaBandeiras();
      this.aviso = {
        ...item,
      };
      this.editandoAviso = true; // para quando salvar, distinguir o que vai entrar como NOVO ou editando.
    },
    async Delete(id_aviso) {
      await axios
        .post(
          `${this.$SERVER_URL}aviso/deletar`,
          { id_aviso: id_aviso },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.listaAvisos();
         
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    async Salvar() {
      this.aviso.userid = Number(localStorage.getItem("userid")); //recuperar userid
      if (this.editandoAviso) {
        await axios
          .put(`${this.$SERVER_URL}aviso/editarAviso`, this.aviso, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
          .then((response) => {
            this.color = "success";
            this.snackbar = true;
            this.snackbarText = response.data.alerta;
            this.aviso = {};
            this.dialog = false;
            this.editandoAviso = false; // voltar pra estado inicial
            this.listaAvisos();
          })
          .catch((error) => {
            this.color = "error";
            this.snackbar = true;
            this.snackbarText = error.response.data.alerta;
          });
      } else {
        console.log(this.aviso);
        await axios
          .post(`${this.$SERVER_URL}aviso/cadastrar`, { ...this.aviso, filiais: this.lojasCriacao }, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
          .then((response) => {
            this.color = "success";
            this.snackbar = true;
            this.snackbarText = response.data.alerta;
            this.mensagem = {}; //limpar os dados setados
            this.filtroCriaAviso = {};
            this.listaAvisos();
          })
          .catch((error) => {
            this.color = "error";
            this.snackbar = true;
            this.snackbarText = error.response.data.alerta;
          });
      }
    },
    async listaAvisos() {
      const filtros = this.filtro;
      this.overlay = true;

      await axios
        .post(`${this.$SERVER_URL}aviso/lista`, { filtro: filtros }, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.dados = response.data;
          this.dadosOriginais = [...this.dados];
          this.overlay = false;
          this.dialog = false;
          this.selectedLines = [];
        });
    },
    async listaAnosMeses() {
      await axios
        .get(`${this.$SERVER_URL}aviso/anosMeses`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.anosMeses = response.data.map(i => { return i.dates });
     
        });
    },
    async listaCluster() {
      await axios
        .get(`${this.$SERVER_URL}aviso/cluster`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.cluster = response.data;
          this.cluster.unshift({nome: 'TODOS', id_cluster: -1})
          this.clusterBackup = this.cluster;
     
        });
    },
    async listaEstados() {
      await axios
        .get(`${this.$SERVER_URL}aviso/estado`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.estados = response.data;
          this.estados.unshift({estado: 'TODOS'})
          this.estadoBackup = this.estados;
        });
    },
    async listaBandeiras() {
      await axios
        .get(`${this.$SERVER_URL}aviso/bandeira`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.bandeiras = response.data;
          this.bandeiras.unshift({ bandeira: 'TODOS' });
          this.bandeiraBackup = this.bandeiras;
        });
    }
  },
  watch: {
    'filtro.filial'(val) {
      const todas = val.filter(i => { return i === -1 });
      if (todas.length > 0) {
        this.lojas = [{label: 'TODOS', id_java: -1}]
      }

      if (todas.length === 0) {
        this.lojas = this.lojasBackup;
      }
    },
    'filtro.estado'(val) {
      const todas = val.filter(i => { return i === 'TODOS' });
      if (todas.length > 0) {
        this.estados = [{estado: 'TODOS'}]
      }

      if (todas.length === 0) {
        this.estados = this.estadoBackup;
      }
    },
    'filtro.cluster'(val) {
      const todas = val.filter(i => { return i === -1 });
      if (todas.length > 0) {
        this.cluster = [{nome: 'TODOS', id_cluster: -1}]
      }

      if (todas.length === 0) {
        this.cluster = this.clusterBackup;
      }
    },
    'filtro.bandeira'(val) {
      const todas = val.filter(i => { return i === 'TODOS' });
      if (todas.length > 0) {
        this.bandeiras = [{bandeira: 'TODOS'}]
      }
      if (todas.length === 0) {
        this.bandeiras = this.bandeiraBackup;
      }
    },
    'filtroCriaAviso.filiais'(val) {
      const todas = val.filter(i => { return i === -1 });
      if (todas.length > 0) {
        this.lojas = [{label: 'TODOS', id_java: -1}]
      }

      if (todas.length === 0) {
        this.lojas = this.lojasBackup;
      }
    },
    'filtroCriaAviso.estado'(val) {
      const todas = val.filter(i => { return i === 'TODOS' });
      if (todas.length > 0) {
        this.estados = [{estado: 'TODOS'}]
      }

      if (todas.length === 0) {
        this.estados = this.estadoBackup;
      }
    },
    'filtroCriaAviso.clusters'(val) {
      const todas = val.filter(i => { return i === -1 });
      if (todas.length > 0) {
        this.cluster = [{nome: 'TODOS', id_cluster: -1}]
      }

      if (todas.length === 0) {
        this.cluster = this.clusterBackup;
      }
    },
    'filtroCriaAviso.bandeira'(val) {
      const todas = val.filter(i => { return i === 'TODOS' });
      if (todas.length > 0) {
        this.bandeiras = [{bandeira: 'TODOS'}]
      }
      if (todas.length === 0) {
        this.bandeiras = this.bandeiraBackup;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .class-on-data-table table {
  table-layout: fixed !important;
}

::v-deep td {
  white-space: pre-wrap !important;
}

.pisca {
  animation: pisca-in 0.8s infinite !important;

  border: solid !important;
  border-color: #8844ee !important;
}

@keyframes pisca {
  0%,
  50%,
  100% {
    border-color: #fff !important;
  }
  51%,
  99% {
    border-color: #8844ee !important;
  }
}
</style>


<template>
  <div>

    <v-container fluid id="element">
      <v-card class="mt-2 py-4 px-2" elevation="20" color="primary">
        <v-row no-gutters>
          <v-col  class="px-2">
            <v-toolbar-title class="ml-2 mt-2 " style="color: #fff">
              Mensagem-Importante
            </v-toolbar-title></v-col
          >
          <v-col>
          
          </v-col>
          <v-col>
          
          </v-col>
          <v-col>
             
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container fluid id="element">
      <v-card class="mt-2 py-4 px-2" elevation="10" height="150">
        <v-row no-gutters height="50">
          <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Filtro Texto"
              v-model="filtro.texto"
              @input="filtrar"
              outlined
              dense
              type="text"
              clearable
            ></v-text-field>
          </v-col>

          
        </v-row>

        <v-row align="end" justify="end">
          <v-col cols="12" align="end" justify="end">
            <v-btn  color="primary" @click="filtro = {}">
              <v-icon left>mdi-filter-off</v-icon> Limpar
            </v-btn>
          </v-col>
        </v-row>

      </v-card>
    </v-container>

    <v-container fluid>

      <button v-on:click="dialog = !dialog">      
          <v-toolbar-title class="ml-2 mt-2 animate__animated animate__pulse animate__repeat-3"
            >Criar Mensagem <v-icon>touch_app</v-icon>
          </v-toolbar-title>   
      </button>

      <v-dialog v-model="dialog" scrollable max-width="1000">
        <v-card class="mt-2 py-4 px-2" elevation="1">
          <form @submit.prevent="Salvar">
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                  <v-text-field
                    label="Data inicial"
                    v-model="mensagem.data_ini"
                    outlined
                    dense
                    type="date"
                    clearable
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
                  <v-text-field
                    label="Data Final"
                    v-model="mensagem.data_fim"
                    outlined
                    dense
                    type="date"
                    clearable
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12" md="8" class="px-2">
                  <v-textarea
                    required
                    :counter="300"
                    filled
                    shaped
                    append-icon="mdi-comment"
                    background-color="amber lighten-4"
                    color="orange orange-darken-4"
                    label="Escreva a mensagem..."
                    v-model="mensagem.texto"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions elevation="20">
              <v-row align="end" justify="end">
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                  lg="3"
                  xl="3"
                  align="end"
                  justify="end"
                >
                  <v-btn color="error" @click="mensagem={},dialog = false">Close</v-btn>
                  <v-btn class="ml-2" type="submit" color="primary">
                    <v-icon left>save</v-icon> Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-container>

    <v-container fluid>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <v-data-table
            :headers="headers"
            :items="dados"
            class="class-on-data-table"
          >
            <template v-slot:[`item.data_ini`]="{ item }">
              {{ item.data_ini | moment }}
            </template>
            <template v-slot:[`item.data_fim`]="{ item }">
              {{ item.data_fim | moment }}
            </template>

            <!-- ========
          Menu 
          ======= -->
            <template v-slot:[`item.acao`]="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
                    mdi-menu
                  </v-icon>
                </template>

                <v-card>
                  <v-list dense nav>
                    <v-list-item link @click="editando(item), dialog = true">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-file-document-edit</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item link @click="Delete(item.id_mensagem)">
                      <v-list-item-icon>
                        <v-icon color="error">mdi-close</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Excluir</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </template>

            <!-- ========
          Menu 
          ======= -->
          </v-data-table>
        </v-row>
      </v-card>
    </v-container>

    <v-snackbar :color="color" v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";

import moment from "moment";


export default {

  data: function () {

    return {
      snackbar: false,
      snackbarText: "",
      color: null,
      dados: [],
      headers: [
        { text: "Ação", value: "acao", align: "center" },
        { text: "Texto", align: "center", value: "texto", width: "30%" },
        { text: "Data Inicio", align: "center", value: "data_ini" },
        { text: "Data Fim", align: "center", value: "data_fim" },
      ],
      mensagem: {},
      editandoMensagem: false,
      dialog: false,
      indicador: "arrow_forward",
      filtro: {},
      dadosOriginais: [],
    };
  },
  async mounted() {
    this.listaMensagens();
  },

  methods: {
    filtrar() {
      this.dados = this.dadosOriginais.filter((x) =>
        x.texto.match(this.filtro.texto)
      );
    },

    async editando(item) {
      this.mensagem = {
        ...item,
        data_ini: moment(item.data_ini).format("YYYY-MM-DD"),
        data_fim: moment(item.data_fim).format("YYYY-MM-DD"),
      };
      this.editandoMensagem = true; // para quando salvar, distinguir o que vai entrar como NOVO ou editando.
    },
    async Delete(id_mensagem) {
      await axios
        .post(
          `${this.$SERVER_URL}mensagem/deletar`,
          { id_mensagem: id_mensagem },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.listaMensagens();
         
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    async Salvar() {
      this.mensagem.userid = Number(localStorage.getItem("userid")); //recuperar userid
      if (this.editandoMensagem) {
        await axios
          .put(`${this.$SERVER_URL}mensagem/editar`, this.mensagem, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
          .then((response) => {
            this.color = "success";
            this.snackbar = true;
            this.snackbarText = response.data.alerta;
            this.mensagem = {};
            this.dialog = false;
            this.editandoMensagem = false; // voltar pra estado inicial
            this.listaMensagens();
          })
          .catch((error) => {
            this.color = "error";
            this.snackbar = true;
            this.snackbarText = error.response.data.alerta;
          });
      } else {
        await axios
          .post(`${this.$SERVER_URL}mensagem/cadastrar`, this.mensagem, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
          .then((response) => {
            this.color = "success";
            this.snackbar = true;
            this.snackbarText = response.data.alerta;
            this.mensagem = {}; //limpar os dados setados
            this.listaMensagens();
          })
          .catch((error) => {
            this.color = "error";
            this.snackbar = true;
            this.snackbarText = error.response.data.alerta;
          });
      }
    },
    async listaMensagens() {
      await axios
        .get(`${this.$SERVER_URL}mensagem/lista`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.dados = response.data;
          this.dadosOriginais = [...this.dados];
     
        });
    },
  },
};
</script>

<style scoped>
::v-deep .class-on-data-table table {
  table-layout: fixed !important;
}

::v-deep td {
  white-space: pre-wrap !important;
}

.pisca {
  animation: pisca-in 0.8s infinite !important;

  border: solid !important;
  border-color: #8844ee !important;
}

@keyframes pisca {
  0%,
  50%,
  100% {
    border-color: #fff !important;
  }
  51%,
  99% {
    border-color: #8844ee !important;
  }
}
</style>


<template>
  <div>

    <v-container fluid id="element">
      <v-card class="mt-2 py-4 px-2" elevation="20" color="primary">
        <v-row no-gutters>
          <v-col  class="px-2">
            <v-toolbar-title class="ml-2 mt-2 " style="color: #fff">
              Justificativas Aplicativo
            </v-toolbar-title></v-col
          >
        </v-row>
      </v-card>
    </v-container>

    <v-container fluid id="element">
      <v-row align="end" justify="end">
        <v-col cols="12" align="end" justify="end">
          <v-btn style="margin-right: 10px" color="primary" @click="dialogCriaJustificativa()">
            <v-icon left>mdi-plus-thick</v-icon> Criar Justificativa
          </v-btn> 
          <v-btn style="margin-right: 10px" v-if="selectedLines.length > 0"  color="error" @click="desabilitar_habilitar('0')">
            <v-icon left>mdi-microsoft-excel</v-icon> Desativar selecionadas
          </v-btn>
          <v-btn style="margin-right: 10px" v-if="selectedLines.length > 0"  color="success" @click="desabilitar_habilitar('1')">
            <v-icon left>mdi-microsoft-excel</v-icon> Ativar selecionadas
          </v-btn>
          <v-btn style="margin-right: 10px"  color="primary" @click="dialogImportar = true">
            <v-icon left>file_upload</v-icon> Importar
          </v-btn>
          <v-btn style="margin-right: 10px"  color="primary" @click="exportar()">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-2 py-4 px-2" elevation="5">
        <v-row no-gutters height="50">
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Justificativa"
              v-model="filtro.texto"
              outlined
              dense
              type="text"
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Data Criação Inicial"
              v-model="filtro.mesInicial"
              :min="'2022-07-01'"
              required
              outlined
              dense 
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-text-field
              label="Data Criação Final"
              v-model="filtro.mesFinal"
              :min="filtro.mesInicial"
              required
              outlined
              dense 
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2" lg="2" xl="2" class="px-2">
            <v-autocomplete
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-magnify"
                v-model="filtro.ativo"
                :items="ativo"
                item-text="label"
                item-value="id_ativo"
                label="Ativo"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row align="end" justify="end">
          <v-col cols="12" align="end" justify="end">
            <v-btn style="margin-right: 10px"  color="primary" @click="filtrar()">
              <v-icon left>mdi-filter</v-icon> Filtrar
            </v-btn>
            <v-btn  color="primary" @click="limpaFiltro()">
              <v-icon left>mdi-filter-off</v-icon> Limpar
            </v-btn>
          </v-col>
        </v-row>

      </v-card>
    </v-container>

    <v-container fluid>

      <v-dialog v-model="dialog" scrollable max-width="600">
        <v-card class="mt-2 py-4 px-2" elevation="1">
          <form @submit.prevent="Salvar">
            <v-card-text>
              <v-row align="center" justify="center">
                <v-col cols="12" md="108" class="px-2">
                  <v-textarea
                    required
                    :counter="255"
                    filled
                    shaped
                    append-icon="mdi-comment"
                    background-color="amber lighten-4"
                    color="orange orange-darken-4"
                    label="Escreva o justificativa..."
                    v-model="justificativa.nome"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions elevation="20">
              <v-row align="end" justify="end">
                <v-col
                  cols="12"
                  sm="6"
                  md="5"
                  lg="6"
                  xl="3"
                  align="end"
                  justify="end"
                >
                  <v-btn color="error"  @click="nome={},dialog = false">Cancelar</v-btn>
                  <v-btn class="ml-2" type="submit" color="primary">
                    <v-icon left>save</v-icon> Salvar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-container>

    <v-container fluid>

      <v-dialog v-model="dialogImportar" max-width="40%">
          <v-card>
            <v-card-title class="subtitle-1 cyan white--text">
              Importar Justificativas!
            </v-card-title>
            <v-card-text class="mt-4"> </v-card-text>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-alert border="left" colored-border prominent dense text type="info">
                   <p>O arquivo CSV deve ser salvo com formato UTF8.<br>
                     O arquivo deve conter as seguintes colunas: <br>
                     - nome<br>
                     - ativo (0 = Não, 1 = Sim)</p>
                  </v-alert>                 
                </v-row>
                <v-row>
                   <v-col cols="12" sm="8" md="8">
                      <v-btn @click="DownloadBase" color="primary">
                        <v-icon left>file_download</v-icon>
                        <span>Download base</span>
                      </v-btn>
                    <v-file-input
                      required
                      accept=".csv" 
                      label="Arquivo csv" 
                      show-size 
                      @change="fileSelected" 
                      prepend-icon="insert_drive_file" 
                      v-model="fileCsv"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="dialogImportar = false">
                <v-icon>mdi-close</v-icon> Cancelar</v-btn
              >
              <v-btn class="cyan" @click="criarJustificativaImportado()">
                <v-icon>mdi-chevron-right</v-icon> Salvar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>

    <v-container fluid>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <template>
            <v-data-table
              v-model="selectedLines"
              :headers="headers"
              :items="dados"
              item-key="id_justificativa"
              show-select
              class="class-on-data-table"
            >
              <template v-slot:[`item.data_criacao`]="{ item }">
                {{ item.data_criacao | moment }}
              </template>
              <template v-slot:[`item.nome`]="{ item }">
                <span>{{ resumirMensagem(item.nome) }}</span>
              </template>

              <!-- ========
            Menu 
            ======= -->
              <template v-slot:[`item.acao`]="{ item }">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="mr-2" color="primary" v-bind="attrs" v-on="on">
                      mdi-menu
                    </v-icon>
                  </template>

                  <v-card>
                    <v-list dense nav>
                      <v-list-item link @click="Delete(item.id_justificativa)">
                        <v-list-item-icon>
                          <v-icon color="withe">mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Excluir</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>

              <!-- ========
            Menu 
            ======= -->
            </v-data-table>
          </template>
        </v-row>
      </v-card>
    </v-container>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-snackbar :color="color" v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="withe" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";

export default {

  data: function () {

    return {
      snackbar: false,
      snackbarText: "",
      color: null,
      dados: [],
      overlay: true,
      headers: [
        { text: "", value: "", align: "center", width: "3%" },
        { text: "Identificação", value: "id_justificativa", align: "center", width: "20%" },
        { text: "Justificativa", align: "center", value: "nome", width: "57%" },
        { text: "Data de criação", align: "center", value: "data_criacao", width: "10%" },
        { text: "Ativo", align: "center", value: "desc_ativo", width: "10%" },
      ],
      justificativa: {},
      dialog: false,
      indicador: "arrow_forward",
      filtro: {
        mesInicial: '',
        mesFinal: ''
      },
      dadosOriginais: [],
      selectedLines: [],
      ativo: [
        {
          label: 'Sim',
          id_ativo: 1
        },
        {
          label: 'Não',
          id_ativo: 0
        }
      ],
      dialogImportar: false,
      csv_file: '',
      justificativaImport: [],
      fileCsv: undefined
    };
  },
  async mounted() {
    await this.listaJustificativa();
  },

  methods: {
    DownloadBase(){
      const data = [{
        'nome':'',
        'ativo': ''
      }]
      const ws = XLSX.utils.json_to_sheet(data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Justificativas para Importação");
        XLSX.writeFile(wb, `base_modelo_importacao_justificativa.csv`);
    },
    async criarJustificativaImportado() {
      if(this.justificativaImport.length <= 0){
        this.color = "error";
        this.snackbar = true;
        this.snackbarText = 'Insira um arquivo para iniciar a importação.';
        return false;
      }
      await axios
        .post(`${this.$SERVER_URL}justificativa/cadastrarImportados`, { justificativas: this.justificativaImport }, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.nome = {}; //limpar os dados setados
          this.listaJustificativa();
          this.csv_file = '';
          this.justificativaImport = [];
          this.dialogImportar = false;
          this.fileCsv = undefined;
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    readerData(rawFile, sheet) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const selectedSheet = workbook.SheetNames[sheet];
          const worksheet = workbook.Sheets[selectedSheet];
          // const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: selectedSheet };
          resolve({ results, meta });
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    async fileSelected($event){
			this.csv_file = $event;
      this.justificativaImport = (await this.readerData(this.csv_file, 0)).results;
      console.log(this.justificativaImport)
    },
    async desabilitar_habilitar(tipo) {
      let tipoFunc = '';
      if(tipo == 1){
        tipoFunc = 'habilitar';
      } else {
        tipoFunc = 'desabilitar';
      }
      await axios
        .post(
          `${this.$SERVER_URL}justificativa/${tipoFunc}`,
          { justificativas: this.selectedLines },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.selectedLines = [];
          this.listaJustificativa();
         
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    resumirMensagem(nome) {
      const limite = 80;
      return nome.length > limite
        ? nome.substring(0, limite) + "..."
        : nome;
    },
    exportar() {
      var justificativa = [];
      this.dados.forEach((val) => {

        justificativa.push({
          'id_justificativa': val.id_justificativa,
          'nome': val.nome,
          'ativo': val.desc_ativo,
          'data_de_criacao': val.data_criacao,
       
        });
      });
 
      const ws = XLSX.utils.json_to_sheet(justificativa);
      let wb = XLSX.utils.book_new();
  
      XLSX.utils.book_append_sheet(wb, ws, "Justificativas");
      XLSX.writeFile(wb, `justificativa${new Date().getTime()}.xlsx`);
    },
    dialogCriaJustificativa() {
      this.dialog = true;
      this.justificativa.nome = '';
    },
    filtrar() {
      this.listaJustificativa();
    },
    limpaFiltro() {
      this.filtro = {
        mesInicial: '',
        mesFinal: ''
      }
    },
    async Delete(id_justificativa) {
      await axios
        .post(
          `${this.$SERVER_URL}justificativa/deletar`,
          { id_justificativa: id_justificativa },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
        .then((response) => {
          this.color = "success";
          this.snackbar = true;
          this.snackbarText = response.data.alerta;
          this.listaJustificativa();
         
        })
        .catch((error) => {
          this.color = "error";
          this.snackbar = true;
          this.snackbarText = error.response.data.alerta;
        });
    },
    async Salvar() {
      let nome = this.justificativa.nome;
      if(nome.length > 255){
        this.color = "error";
        this.snackbar = true;
        this.snackbarText = 'O valor da Justificativa ultrapassou o limite de 255 caracteres.';
        return false;
      } else {
        await axios
            .post(`${this.$SERVER_URL}justificativa/cadastrar`, { nome: nome }, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
              },
            })
            .then((response) => {
              this.color = "success";
              this.snackbar = true;
              this.snackbarText = response.data.alerta;
              this.justificativa.nome = {}; //limpar os dados setados
              this.listaJustificativa();
            })
            .catch((error) => {
              this.color = "error";
              this.snackbar = true;
              this.snackbarText = error.response.data.alerta;
            });
      }
    },
    async listaJustificativa() {
      const filtros = this.filtro;
      this.overlay = true;

      await axios
        .post(`${this.$SERVER_URL}justificativa/lista`, { filtro: filtros }, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
        .then((response) => {
          this.dados = response.data;
          this.dadosOriginais = [...this.dados];
          this.overlay = false;
          this.dialog = false;
          this.selectedLines = [];
        });
    }
  }
};
</script>

<style scoped>
::v-deep .class-on-data-table table {
  table-layout: fixed !important;
}

::v-deep td {
  white-space: pre-wrap !important;
}

.pisca {
  animation: pisca-in 0.8s infinite !important;

  border: solid !important;
  border-color: #8844ee !important;
}

@keyframes pisca {
  0%,
  50%,
  100% {
    border-color: #fff !important;
  }
  51%,
  99% {
    border-color: #8844ee !important;
  }
}
</style>

